import styled from 'styled-components'

export const BlogPostContianer = styled.div`
    width: 50%;
    margin: 0 auto;
    line-height: 1.8;

    @media (max-width: 800px) {
        width: 90%; 
    }

    h3 {
        color: #666;
    }

    blockquote {
        padding-left: 20px;
        font-style: italic;
        border-radius: 5px;
        border-left: 3px solid black;
    }

    a {
        text-decoration: none;
        color: black;
        font-weight: bolder;
    }

    pre {
        margin-bottom: 1.5rem;
        line-height: 1.42;
        background: hsla(0,0%,0%,0.04);
        border-radius: 3px;
        overflow: auto;
        padding: 20px;

        code {
            padding: 0px 1.25rem;
            line-height: 2;
        }
    }
`;