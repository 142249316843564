import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout';
import Head from '../components/head';

import {
    BlogPostContianer
} from './blog.style';

export const  query = graphql`
    query ($slug: String!){
        markdownRemark (fields: { slug: { eq: $slug } }) {
        frontmatter{
            title
            subtitle
            date
        }
        html
        }
    }
`

const BlogTemplate = (props) => {
    return (
        <Layout>
            <Head title={props.data.markdownRemark.frontmatter.title} />
            <BlogPostContianer>
                <h1>{props.data.markdownRemark.frontmatter.title}</h1>
                <h3>{props.data.markdownRemark.frontmatter.subtitle}</h3>
                <div dangerouslySetInnerHTML = {{__html: props.data.markdownRemark.html}}></div>
            </BlogPostContianer>
        </Layout>
    )
}

export default BlogTemplate;